<template>
  <div class="content-container">
    <div class="center-container">
      <p class="title">动态心脏生理&分时行为异常数据汇总</p>
      <div class="action-bar">
        <div class="action-bar-left">
          <Form :label-width="90">
            <Form-item label="创建时间：">
              <DatePicker type="date" format="yyyy-MM-dd" placeholder="选择时间" style="width: 160px" @on-change="handleChange"></DatePicker>
            </Form-item>
          </Form>
        </div>
        <div class="action-bar-right">
          <Button type="primary" @click="importPhysiology">导入生理数据</Button>
          <Button type="primary" @click="importBehavior">导入行为数据</Button>
        </div>
      </div>
      <table class="table">
        <tr>
          <th>指标</th>
          <th>心搏</th>
          <th>最快心率</th>
          <th>最慢心率</th>
          <th>平均心率</th>
          <th>停搏漏搏</th>
          <th>房性早搏</th>
          <th>室性早搏</th>
          <th>行为1</th>
          <th>行为2</th>
          <th>行为3</th>
          <th>行为4</th>
        </tr>
        <tr v-for="(row, index) in physiologyData" :key="index">
          <td v-for="(data, i) in row" :key="row[0] + i">{{data}}</td>
        </tr>
      </table>
    </div>
    <div class="symbol-box tac">
      <Button type="primary" size="large" @click="$router.back()">返回</Button>
      <Button type="success" size="large" @click="handleSubmit">提交</Button>
    </div>
  </div>
</template>

<script>
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			createDate: '',
			physiologyData: [],
		};
	},
	methods: {
		handleChange(date) {
			this.createDate = date;
		},
		importPhysiology() {
			if (this.createDate) {
				const params = {
					member_id: this.$route.params.member_id,
					xm: 3,
					sjrq: this.createDate,
				};
				MemberService.getECGList(params).then((data) => {
					if (data.length > 0) {
						this.physiologyData = JSON.parse(data.reverse()[0]['nr']);
					} else {
						this.$Message.warning('没有检索到相关数据');
					}
				});
			} else {
				this.$Message.error('请先选择创建日期');
			}
		},
		importBehavior() {
			if (this.physiologyData.length > 0) {
				const params = {
					member_id: this.$route.params.member_id,
					xm: 2,
					sjrq: this.createDate,
				};
				MemberService.getECGList(params).then((data) => {
					if (data.length > 0) {
						const res = JSON.parse(data.reverse()[0]['nr']);
						const keys = Object.keys(res).map((item) => item.split(':')[0]);
						this.physiologyData = this.physiologyData.map((item) => {
							const hour = item[0].split(':')[0].padStart(2, '0');
							if (keys.indexOf(hour) > -1) {
								const mappedBehavior = res[hour + ':00'];
								for (let i = 0; i < 4; i++) {
									item.push(mappedBehavior[i] ? mappedBehavior[i]['content'] || '' : '');
								}
							} else {
								item = item.concat(new Array(4).fill(''));
							}
							return item;
						});
					} else {
						this.$Message.warning('没有检索到相关数据');
					}
				});
			} else {
				this.$Message.error('请先导入生理数据');
			}
		},
		handleSubmit() {
			if (this.physiologyData.length) {
				const params = {
					member_id: this.$route.params.member_id,
					xm: 1,
					sjrq: this.createDate,
					nr: JSON.stringify(this.physiologyData),
				};
				MemberService.submitECGData(params).then(() => {
					this.$Message.success('提交成功');
					this.$router.back();
				});
			} else {
				this.$Message.error('请先导入生理数据');
			}
		},
	},
};
</script>

<style lang="less" scoped>
.content-container {
  background-color: #fff;
  padding: 15px;
  margin-top: 10px;
  .center-container {
    width: 1000px;
    margin: 0 auto;
  }
  .title {
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
  }
  .action-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 52px;
  }
  .table {
    width: 100%;
    th, td {
      border: 1px solid;
      padding: 5px 10px;
      text-align: center;
    }
  }
  .symbol-box {
    margin-top: 20px;
  }
}
</style>
